body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-root {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);
}

.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
  font-weight: bolder;
}

.sign-in {
  max-width: 540px;
}

.pb-05 {
  padding-bottom: 0.125rem !important;
}

.google-btn {
  font-size: 14px;
}

.navbar-collapse.collapse.show .btn,
.navbar-collapse.collapsing .btn {
  margin-top: 1.5rem !important;
  margin-bottom: 0.25rem !important;
  width: 100%;
}


.toggle-custom-styles .react-toggle-thumb {
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-width: 0;
}

.toggle-custom-styles.react-toggle--checked .react-toggle-thumb {
  left: 28px;
}

.toggle-custom-styles.react-toggle--checked .react-toggle-track {
  background-color: #3498db;
}

.toggle-custom-styles.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #2980b9;
}

.inactive {
  opacity: 0.3
}

/* make header bottom border same color as others */
.table > :not(:last-child) > :last-child > th {
  border-bottom-color: #dee2e6;
}

/* add shades when table overflows horizontally */
.table-wrapper {
  /* overflow: auto; */
  max-width: 100%;
  background:
    linear-gradient(to right, white 30%, rgba(255,255,255,0)),
    linear-gradient(to right, rgba(255,255,255,0), white 70%) 0 100%,
    radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
}

.table-warning {
  --bs-table-bg: #fff3cd77;
  --bs-table-striped-bg: #f2e7c377;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb977;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be77;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb977;
}
